@font-face {
  font-family: FKGrotesk;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(/static/fonts/FKGrotesk-Regular-subset.woff2?v=1) format("woff2"),
    url(/static/fonts/FKGrotesk-Regular-subset.woff?v=1) format("woff");
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: FKGrotesk;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(/static/fonts/FKGrotesk-Medium-subset.woff2?v=1) format("woff2"),
    url(/static/fonts/FKGrotesk-Medium-subset.woff?v=1) format("woff");
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: FKGroteskMono;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    url(/static/fonts/FKGroteskMono-Regular.woff2?v=1) format("woff2"),
    url(/static/fonts/FKGroteskMono-Regular.woff?v=1) format("woff");
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: FKGroteskMono;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src:
    url(/static/fonts/FKGroteskMono-Medium.woff2?v=1) format("woff2"),
    url(/static/fonts/FKGroteskMono-Medium.woff?v=1) format("woff");
  font-display: swap;
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
    U+FFFD;
}

@font-face {
  font-family: "Arial Fallback";
  src: local(Arial);
  ascent-override: 91%;
}

/* Move to vanilla extract to have typed variables */
:root {
  --primaryFont: FKGrotesk, "Arial Fallback", Arial, Helvetica, sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  line-height: 1.25;
  font-weight: 400;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Tooltips are rendered at root level and break mobile. */
  font-family: var(--primaryFont);
  cursor: default;
}

/*
 * TODO: enable box-sizing fix at a convenient moment. Will require lots of visual testing and probably fixes.

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
*/

code {
  font-family: "FKGroteskMono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  letter-spacing: -0.06em;
}

.option-list a {
  color: inherit;
}

.option-list a:hover {
  opacity: 0.7;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
button {
  cursor: pointer;
  color: inherit;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

button {
  background: none;
  padding: 0;
  text-align: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  letter-spacing: inherit;
}

/* button:focus {
  outline: none;
} */

select,
input,
textarea,
button {
  border-radius: 0;
  border: 0;
}
